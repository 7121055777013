import React, { Component } from 'react';
import { Link } from "react-router-dom";


class HomePage extends Component<any,any> {
    handleClick=()=>{
        this.props.getSixListings()
        console.log("list")
    }
render() {
return (
<div>
    <div className="container" style={{marginTop: "2rem"}}>
        <div className="row">
            <div className="seven columns">
                <b>
                    <h1 style={{marginBottom: "0"}}>Nick Cook <br/>Retail & Leisure</h1>
                </b>
                <h4>Chartered Surveyor</h4>
            </div>
            <div className="four columns">
                <p style={{fontSize:"1.9rem",marginTop: "3rem"}}>Nick Cook is a Chartered Surveyor with over 30 years' experience in the
                    retail and leisure sector.</p>
            </div>
        </div>
    </div>

    <div style={{paddingTop: "2rem"}}></div>
    <div style={{backgroundColor: "#e6e6e6"}}>
        <div className="container" style={{paddingTop: "3rem", width: "100%"}}>
            <div className="row">
                <div className="one-half column value" style={{float:"left"}}>
                    <h2 className="value-multiplier" style={{textAlign: "center", marginBottom: "1rem"}}>About</h2>
                    <h5 className="value-heading" style={{textAlign: "center", marginBottom: "1rem"}}>Read More About Us
                    </h5>

                    <div className="center">
                        <a className="button" href="about">About Page</a>
                    </div>

                </div>

                <div className="one-half column value" style={{float: "left"}}>
                    <h2 className="value-multiplier" style={{textAlign: "center", marginBottom: "1rem"}}>Contact</h2>
                    <h5 className="value-heading" style={{textAlign: "center", marginBottom: "1rem"}}>Contact Details
                    </h5>

                    <div className="center">
                        <a className="button" href="/contact">Contact Page</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <h2 style={{textAlign: "center", paddingTop: "1rem"}}>Our Property Listings</h2>
    <div className="section hero">
        <div className="row">
            <h4 className="hero-heading" style={{textAlign: "center"}}>Here is a selection of our current listed
                properties.</h4>
            <div className="center">
                <a className="button button-primary" href="/listings">More properties can be found here</a>
            </div>


        </div>
    </div>

    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Letchworth12aCentralApproach.png")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>12a Central Approach</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/Letchworth12aCentralApproach.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/25LeysAvenue.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>25 Leys Avenue</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/Letchworth25LeysAvenue.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Unit12-14.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 12-14</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/GraysUnit1214.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
        </div>
    </div>
</div>);
}
}


export default HomePage;
