import React, { Component } from 'react';

class News extends Component {
    render() {
        return (
            <div >
            <div >
                <div style={{marginTop:"10rem" }}>
                <h2 >Page in development....</h2>
                <h3>Email me at NCook@nickcookrl.com for any enquires</h3>
                </div>
              
            </div>
              <div style={{height:"10rem" }}></div>
            </div>
        );
    }
}

export default News;