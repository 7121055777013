import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Listings extends Component<any,any> {
    render() {
        return (
    <div >
                <h1>Properties</h1>
                <br/>
                <br/>
                <h2> Grays</h2>
                <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Unit7.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 7</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/GraysUnit7.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Unit12-14.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 12-14</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/GraysUnit1214.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Unit26.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 26</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/GraysUnit26.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
        </div>
    </div>

    <div className="container" style={{width: "100%", paddingTop: "1rem", paddingBottom: "2rem"}}>
        <div className="row">
            <div className="one-third column value">
            <img className="phone" src={require("../styling/images/ListingImages/Unit34.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 34</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/GraysUnit34.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>

            <div className="one-third column value">
            <img className="phone" src={require("../styling/images/ListingImages/Unit36.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>Unit 36</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign: "center"}}>Grays</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
                 <Link className="button" to="/files/GraysUnit36.pdf" target="_blank">Download PDF</Link>
                </div>
            </div>
        </div>
    </div>
    <br/>
    <br/>
    <br/>

    <h2>Stevenage</h2>
    <div className="container" style={{width: "100%"}}>

      <div className="row">
        <div className="one-third column value">
          <img className="phone" src={require("../styling/images/ListingImages/3aStevenage.png")} style={{width: "100%"}} />
          <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>3a Westgate Centre</h3>
          <i>
          <h5 className="value-heading" style={{textAlign:"center"}}>Stevenage</h5>
          </i>
          <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
            <div className="center">
            <Link className="button" to="/files/3aStevenageCentre.pdf" target="_blank" >Download PDF</Link>
            </div>
        </div>
        <div className="one-third column value">
          <img className="phone" src={require("../styling/images/ListingImages/4aStevenage.png")} style={{width: "100%"}} />
          <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>4a Westgate Centre</h3>
          <i>
          <h5 className="value-heading" style={{textAlign:"center"}}>Stevenage</h5>
          </i>
          <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
            <div className="center">
              <Link className="button" to="/files/4aStevenage.pdf" target="_blank">Download PDF</Link>
            </div>
        </div>
        <div className="one-third column value">
          <img className="phone" src={require("../styling/images/ListingImages/6aStevenage.png")} style={{width: "100%"}} />

          <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>6a Westgate Centre</h3>
          <i>
          <h5 className="value-heading" style={{textAlign:"center"}}>Stevenage</h5>
          </i>
          <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
            <div className="center">
              <Link className="button" to="/files/6aStevenageCentre.pdf" target="_blank">Download PDF</Link>
            </div>
        </div>
      </div>
    </div>

    <div className="container" style={{width: "100%", paddingTop: "1rem", paddingBottom: "2rem"}}>
    <div className="row">
    <div className="one-third column value">
    <img className="phone" src={require("../styling/images/ListingImages/9Stevenage.png")} style={{width: "100%"}} />

    <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>9 Westgate Centre</h3>
    <i>
        <h5 className="value-heading" style={{textAlign:"center"}}>Stevenage</h5>
    </i>
    <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
    <div className="center">
     <Link className="button" to="/files/9Stevenage.pdf" target="_blank">Download PDF</Link>
    </div>
    </div>

    <div className="one-third column value">
    <img className="phone" src={require("../styling/images/ListingImages/19aStevenage.png")} style={{width: "100%"}} />
    <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>19a Westgate Centre</h3>
    <i>
        <h5 className="value-heading" style={{textAlign:"center"}}>Stevenage</h5>
    </i>
    <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
    <div className="center">
     <Link className="button" to="/files/19aStevenage.pdf" target="_blank">Download PDF</Link>
    </div>
    </div>
    </div>
    </div>
    <br/>
    <br/>
    <br/>


    <h2> Letchworth </h2>
    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/3CommerceWay.png")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>3 Commerce Way</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/3CommerceWay.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>

            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/5CommerceWay.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>5 Commerce Way</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/5CommerceWay.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>

            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/21CommerceWay.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>21 Commerce Way</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/21CommerceWay.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/Letchworth12aCentralApproach.png")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0", fontSize: "32px"}}>12a Central Approach</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/Letchworth12aCentralApproach.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>

            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/1214CentralApproach.png")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0", fontSize: "32px"}}>12-14 Central Approach</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/1214CentralApproach.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>

            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/25LeysAvenue.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>25 Leys Avenue</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Letchworth</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/Letchworth25LeysAvenue.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
        </div>
   </div>
    <br/>
    <br/>
    <br/>
   <h2>Southend-On-Sea</h2>
    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/113-High-Street.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>113 High Street</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Southend-On-Sea </h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/southend_hs_113.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
            </div>
    </div>
    <br/>
    <br/>
    <br/>
   <h2>Nottingham</h2>
    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/26WheelerGate.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>26 Wheeler Gate</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Nottingham</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/26WheelerGate.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
        </div>
     </div>
    <br/>
    <br/>
    <br/>

   <h2>Salisbury</h2>
    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/15MinsterStreet.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>15 Minster Street</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Salisbury</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/15MinsterStreet.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
        </div>
     </div>

    <br/>
    <br/>
    <br/>

   <h2>Bognor Regis</h2>
    <div className="container" style={{width: "100%"}}>
        <div className="row">
            <div className="one-third column value">
                <img className="phone" src={require("../styling/images/ListingImages/4LondonRoad.jpg")} style={{width: "100%"}} />
                <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>4 London Road</h3>
                <i>
                    <h5 className="value-heading" style={{textAlign:"center"}}>Bognor Regis</h5>
                </i>
                <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                <div className="center">
               <Link className="button" to="/files/4_London_Road.pdf" target="_blank" >Download PDF</Link>
                </div>
            </div>
        </div>
     </div>

     <br/>
     <br/>
     <br/>

     <h2>Ryde</h2>
      <div className="container" style={{width: "100%"}}>
          <div className="row">
              <div className="one-third column value">
                  <img className="phone" src={require("../styling/images/ListingImages/38HighStreet.jpg")} style={{width: "100%"}} />
                  <h3 className="value-multiplier" style={{textAlign: "center", marginBottom: "0"}}>38 High Street</h3>
                  <i>
                      <h5 className="value-heading" style={{textAlign:"center"}}>Ryde</h5>
                  </i>
                  <p className="value-description" style={{textAlign: "center"}}>Click below for a PDF of the above property</p>
                  <div className="center">
                 <Link className="button" to="/files/38HighStreet.pdf" target="_blank" >Download PDF</Link>
                  </div>
              </div>
          </div>
       </div>
</div>    );
    }
}

export default(Listings);
