import React, { Component } from 'react';
import '../styling/aboutUs.css'
import '../styling/normalize.css';
import '../styling/skeleton.css';
import '../styling/stylesheet.css';

class About extends Component {
    render() {
        return (
          <div style={{textAlign:'left'}}>
 <div className="container" style={{height: "0", width: "100%"}}>
    <div className="aboutImage">
    <img src={require("../styling/images/ProfilePicture.png")} style={{borderRadius: "50%",  width: "100%",height:"100%"}} alt="img"/>    
    </div>
    <h2 className="aboutHeader color: #e3e3e3">Nick Cook</h2>
    <h4 className="aboutSubheading">Chartered Surveyor</h4>
    </div>     
    <div style={{backgroundColor: "white", height: "100%", paddingTop: "10rem", float: "none"}}>
    <div className="container">
      <br />
      <p>
     Initially Nick worked as an Estates Manager at Bhs Plc dealing with all aspects of store acquisition and asset management. 
     His time at Bhs Plc was followed by 18 years at Douglas Stevens and Company Limited as an agent. Whilst working at Douglas Stevens his 
          responsibilities included retail agency, retail investment and lease advisory - in addition Nick has had experience 
          in property finance and understands the delicate balance between lending institutions and borrowers. Typically acting for a borrower in 
          conjunction with the bank to provide a solution where, for example, a tenant has gone into receivership which results in a breach of 
          the loan to value covenants. This can involve finding a new tenant on acceptable terms and then possibly disposing of the asset.
      </p>
      <p>
        At Douglas Stevens Nick also handled a number of shopping centres including; 
      <ol>
      <li>Newlands Centre Kettering 265,614 ft2</li>
      <li>Market Gates Great Yarmouth 219,248 ft2</li>
      <li>Thamesgate Shopping Centre Gravesend 94,428ft2</li>
      <li>Waterbourne Walk Leighton Buzzard 93,000ft2</li>
      <li>Chiltern Centre High Wycombe 40,000ft2</li>
      <li>Gray’s Shopping Centre Gray’s 209,300ft2</li>
      <li>The Royals Southend 284,959ft2</li>
       <li>Quadrant Centre Dunstable 140,000ft2   </li>       
      <li>Crystal Peaks Sheffield 635,000ft2</li>
      <li>Cross Keys Shopping Centre Salisbury 68,751ft2.</li>
      </ol>
      </p>
      <p>
          Nick also dealt with lease advisory matters on a number of these schemes
          Perhaps the high point of Nick’s career with Douglas Stevens was the acquisition of former
          Bhs store for Primark in Milton Keynes which measured 92,000 ft2 and the extension of Primark’s Merryhill store to 84,019ft2.
          Other stores acquired on behalf of Primark include stores Blackburn, Walsall, Ealing. Middlesborough, and Northampton.
          Shopping centres Nick currently deals with include Gray’s Shopping Centre Grays, The Royals Southend and Crystal Peaks Sheffield. 
      </p>
      
      <p>
          In summary Nick provides advice to both retail and leisure occupiers, landlords and developers and offers a wide range of services, including:
      </p>      
      <ul >
        <li>Retail Leisure and Agency advice on Shopping Centres and High Street
        shops.
      </li>
      <li>Investment agency including both purchase and sales advice.</li>
      <li>Lease advisory.</li>
      <li>Asset management advice on a strategic level.</li>
      </ul>

      <p>
        Nick Cook Retail & Leisure endeavours to provide the best in class
        advice and results and aims to provide complete solutions and to build
        long term working relationships with our clients.
      </p>
    </div>
    </div>                                   
          </div>  
        )};
}

export default About;
