import React from 'react';
import About from  './container/About';
import News from './container/News'
import Contact from './container/contact';
import HomePage from './container/HomePage';
import Listings from './container/Listings';
import './styling/normalize.css';
import './styling/skeleton.css';
import './styling/stylesheet.css';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

class App extends React.Component<any,any> {
  render(){
  return (
    <div>
    <Router>
    <div className="App" style={{textAlign:'center',width: "100%",height:"100%", position:'absolute'}}>  
      <div className="hero-image" style={{width: "100%"}}>       
      <div style={{width: "100%", height: "7rem", backgroundImage: "linear-gradient(to bottom, rgba(33, 33, 33,1), rgba(20, 20, 20, 0))", marginTop: "0"}}> 
          <div className ="container" style={{width: "100%", height: "100%"}}>
          <div className="logobox" style={{paddingLeft:"20px"}}>
          <img src={require('./styling/images/LogoBackground.png')} alt='companyLogo' style={{width: "100%" ,height: "100%", float: "left",paddingTop:"10px"}}/>
                  </div>  
          <div className="navContainer">                   
          <div className="topnav" id="myTopnav">
          <a href="javascript:void(0);" className="icon" onClick={()=>this.sideMenu()}><div>
              <img
                src={require("./styling/images/MobileBurger.png")}
                alt="burgerIcon"
                style={{
                  width: "100%",
                  height: "100%",
                  float: "left",
                  objectFit: 'cover',
                  
                }} />
            </div>
          </a>                    
          <a href="/" className="active">Home</a>
          <a href="about">About</a>
          <a href="contact">Contact</a>
          <a href="listings">Property Listings</a>
          <a href="news">News</a>      
          </div>      
          </div>
        </div>         
    </div>
    </div>    
      <div style={{display:"inline-block",width:"100%",height:'auto'}}>
        <Switch>
          <Route exact path="/about" component={About} />          
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/listings" component={Listings} />
          <Route exact path="/news" component={News} />          
          <Route exact path="/" component={HomePage} style={{width:"100%"}}/>
        </Switch>
        <div style={{backgroundColor: "#e6e6e6"}}>
    <div className="container">
        <div className="row" style={{paddingTop: "2rem"}}>
            <div className="one-half column">
            <h5>Nick Cook Retail & Leisure</h5>
            <p style={{fontSize: "1.5rem"}}>  With Nicks experience comes an extensive track record including acting for Primark
                Stores Ltd, ABF Plc, JD Wetherspoon’s, Canada Life, Lloyds Bank, New River,
                Sapcotes, Bank of Ireland, Hunters Property, Custodian Capital Ltd, West Bromwich
                (Commercial Bank), Amsprop as well as number of private investors and small property
                 companies.</p>
            </div>         
            <div className="one-half column">
            <h5>Contact Us</h5>
            <p style={{fontSize: "1.5rem"}}>Email: NCook@nickcookrl.com</p>
            <p style={{fontSize: "1.5rem"}}>Tel: +44 7884433062</p>
            </div>
        </div>
        
        <div style={{borderTop: "ridge", paddingTop: "1rem"}}>
         <p style={{fontSize: "1rem", textAlign: "center"}}>Nick Cook Retail and Leisure Limited  - Registered in England Company Number 12654018</p>
        <p style={{fontSize: "1rem", textAlign: "center"}}>"London" by HerryLawford is licensed under CC BY 2.0</p>
        </div>
    </div>
    </div>
      </div>   
      </div>     
     </Router>
</div>
);}

  private sideMenu() {
    var x:any = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }    
  }


}


export default App;
